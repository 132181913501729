import axios from 'axios';
import React, { useContext, useState } from 'react'
import { DataContext } from '../context/DataContext';
import baseUrl from '../utils/baseUrl';


const ChangePassword = () => {
  
  const {data} = useContext(DataContext)

  const INITIAL_USER = {
    contrato: data.contracto.contrato,
    password: data.afiliado1.cedula,
    newpassword: ''
  };
  const [user, setUser] = useState(INITIAL_USER)
  const [state, setState] = useState(false)
  const [error, setError] = useState(false)
  const [loading, setLoading] = useState(false)
  const [disabled, setDisabled] = useState(false)
  
  const handleChange = (e) => {
		const { name, value } = e.target;
		setUser((prevState) => ({
			...prevState,
			[name]: value,
		}));
	};

  function redireccionar(){
    window.location.href = "/";
    localStorage.clear();
  }
   

  const handleSubmit =async (e) => {
    e.preventDefault();
    setLoading(true)
    setState(true)
    setDisabled(true)

    try {
      const url =`${baseUrl}/api/users/changepassw`;
      await axios.post( url, user, {
        header : 'Content-Type Aplication/json'
      })
      setTimeout(() => {
        redireccionar()
      }, "2000")
    } catch (error) {
      setError(true)
    }
  }
  return (
    <section className='centrar bg-degrade'>
      <div className="container py-3">
        <div className="row d-flex justify-content-center align-items-center">
          <div className="col col-xl-5">
            <h3 className='mt-5 text-center text-title'>¡Cambio de contraseña!</h3>
            <div className="card p-4 Mycard">
              <div className='centrar'>
                <img className='img-login' src='/images/logo-navidad.jpg' alt="logo" />
              </div>
              <form onSubmit={handleSubmit}>
                {
                  state ?
                    <div className="m-1 text-center alert alert-success alert-dismissible fade show" role="alert">
                      <strong>Felicidades!</strong> Contraseña cambiada correctamente.
                      <button onClick={ () => setState(false) } type="button" className="btn-close" ></button>
                    </div>
                  : ''
                }
                {
                  error ?
                  <div className="m-1 text-center alert alert-danger alert-dismissible fade show" role="alert">
                      <strong>¡Ocorrio un error!</strong> intentelo de nuevo.
                      <button onClick={ () => setState(false) } type="button" className="btn-close" ></button>
                    </div>
                    : ''
                }
                
                <p className='m-3 text-center'><strong>Es necesario realizar el cambio de contraseña</strong></p>
                <div className='form-group'>
                  <label>Nueva Contraseña</label>
                  <input
                    className='form-control'
                    placeholder='Nueva Contraseña'
                    name='newpassword'
                    type='password'
                    value={user.newpassword}
                    onChange={handleChange}
                    required
                  />
                </div>
                
                <div className='centrar'>
                  <button className='mt-3 btn-login' type='submit'
                    disabled={disabled}
                  >
                    {loading ? (
                      <>
                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        {' '}Cargando...
                      </>
                    ) : (
                    'Cambiar contraseña'
                    )}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default ChangePassword