import React  from 'react';
import Footer from '../components/footer';
// import { DataContext } from '../context/DataContext';
import ChatWood from '../common/bot/ChatWood';


const Main = ( {children} ) => {
  // const {data} = useContext(DataContext)
  
  return (
    <div className=''>
      {children}
      <ChatWood />

        {/* {
          data !== null ?
          <a className='text-title' href='https://api.whatsapp.com/send?phone=573137597403' target="_blank"  rel="noreferrer noopener">
            <button className='btn-WhatsApp'>
              <span className="msg-whatsApp">¿Necesitas ayuda?</span>
            </button>
          </a>
          : ''  
        } */}
      <Footer />
    </div>
  )
}

export default Main