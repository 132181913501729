import React from 'react'
import { NavLink, useLocation } from 'react-router-dom';

const Navbar = () => {

  const location = useLocation();
  const logOut = () =>{
    localStorage.clear();
  }
  const user = JSON.parse(localStorage.getItem("userInfo"));
  return (
    <nav className={ location.pathname === '/' ? 'navbar sticky-top navbar-expand-lg ': 'navbar sticky-top navbar-expand-lg bg-light color-nav'}>
      <div className="container-fluid">
        <a className="navbar-brand" href="/">
          <img src="/logo_header.png" alt="Grupo Alianza Colombia Viajar" width={200} />
        </a>
        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarTogglerDemo02" aria-controls="navbarTogglerDemo02" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>
        {
          user != null ?
            <div className="collapse navbar-collapse" id="navbarTogglerDemo02">
              <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                <li className="nav-item">
                  <NavLink to="/dashboard">
                    <button className={`${location.pathname === '/dashboard' ? 'btn-menu-active' : 'btn-menu'}`}>
                      Membresía
                    </button>
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink to="/reserva">
                    <button className={`${location.pathname === '/reserva' ? 'btn-menu-active' : 'btn-menu'}`}>
                      Reservar
                    </button>
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink to="/alianza-plus">
                    <button className={`${location.pathname === '/alianza-plus' ? 'btn-menu-active' : 'btn-menu'}`}>
                      Alianza Plus
                    </button>
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink to="/soporte">
                    <button className={`${location.pathname === '/soporte' ? 'btn-menu-active' : 'btn-menu'}`}>
                      Soporte
                    </button>
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink to="/">
                    <button className='btn-menu' onClick={ () => logOut()}>
                      Cerrar Sesión
                    </button>
                  </NavLink>
                </li>
              </ul>
            </div>
          : ''
        }
      </div>
    </nav>
  )
}

export default Navbar