import React from 'react';
import './index.css';
import './styles/main.css'
import Main from './layout/main';
import { DataProvider } from './context/DataContext';
import RouterApp from './router/routes';


const App = () => {
  
  return (
    <DataProvider>
      <Main>
        <RouterApp />
      </Main>
    </DataProvider>
  )
}

export default App