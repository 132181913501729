import React, { useContext, useEffect, useState } from 'react'
import baseUrl from '../../utils/baseUrl';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { DataContext } from '../../context/DataContext';
import Navbar from '../Navbar';
import NoData from '../err/NoData';

const INITIAL_USER = {
	contrato: '',
	password: '',
};

const Signin = () => {
  const navigate = useNavigate();
  const [user, setUser] = useState(INITIAL_USER)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const [mora, setMora] = useState(false)
  const [msg, setMsg] = useState('')
  const [disabled, setDisabled] = useState(false)
  const [upkeep, setUpkeep] = useState(false)
  const { setData } = useContext(DataContext)
  const [showPassword, setShowPassword] = useState(false)
  
  useEffect(() => {
    setData(null);
    const userValidate = localStorage.getItem("userInfo") || '';
    if(userValidate !== ''){
      setTimeout(() => {
        window.location.href = "/dashboard";
      }, "0")
    }

    const isUser = Object.values(user).every((el) => Boolean(el));
    isUser ? setDisabled(false) : setDisabled(true);
    setError(false)
    setMora(false)
    setUpkeep(false)
  }, [user, setData])
  
  const handleChange = (e) => {
		const { name, value } = e.target;
		setUser((prevState) => ({
			...prevState,
			[name]: value,
		}));
	};

  const handleSubmit = async (e) => {
		e.preventDefault();
    setDisabled(true)
    setLoading(true)
		try {
			const url = `${baseUrl}/api/users/getuser`;
			const payload = { ...user };
			const response = await axios.post(url, payload, {
        header : 'Content-Type Aplication/json',
      });
      setLoading(false)
      setData(response.data.data)
      if(response.data.data.validaciones.mora === '1'){
        setMora(true)
        setMsg(response.data.data.validaciones.mmora)
      }else if(response.data.status === 500){
        setError(true)
      }else{
        if( Object.keys(response.data.data.afiliado1).length> 0){
          localStorage.setItem('userInfo', JSON.stringify(response.data.data) );
          navigate("/reserva")
        }else{
          setUpkeep(true)
        }
      } 
      setDisabled(false)
		} catch (error) {
      console.log(error);
      setError(true)
		} finally {
      setDisabled(false)
			setLoading(false);
		}
	};

  return (
    <div className='container-fluid bg-login'>
      <Navbar />
      <section className="centrar bg-degrade">
        <div className="container">
          <div className="row d-flex justify-content-center align-items-center">
            <div className="col col-xl-4">
              <div className="Mycard">
                <div className='centrar'>
                  <img src='/images/AlianzaPriority2.webp' alt="Grupoa Alianza Colombia" height={57} width={157} />
                </div>
                <form onSubmit={handleSubmit} className="mt-4">
                  {
                    error ?
                      <div className="m-1 text-center alert alert-danger alert-dismissible fade show" role="alert">
                        <strong>¡Error!</strong> Contrato o contraseña incorrectos.
                        <button onClick={ () => setError(false) } type="button" className="btn-close" ></button>
                      </div>
                    : ''  
                  }
                  {
                    mora  ?
                    <div className="m-1 text-center alert alert-danger alert-dismissible fade show" role="alert">
                      <strong>¡Cartera en Mora Financiación! {msg.slice(29)} </strong>
                    </div>
                    : ''  
                  }
                  {
                    upkeep ? <NoData /> : ''
                  }
                  <div className="input_container-login">
                    <label className="input_label-login">Contrato</label>
                    <svg
                      className="icon-login"
                      xmlns="http://www.w3.org/2000/svg"
                      id="_x32_"
                      fill="#000"
                      version="1.1"
                      viewBox="0 0 512 512"
                      xmlSpace="preserve"
                    >
                      <g id="SVGRepo_iconCarrier">
                        <style></style>
                        <path
                          d="M208.971 443.563a11.051 11.051 0 003.234-8l8.047-8.031 65.25-14a19.904 19.904 0 0014.859-11.984s12.375-27.641 19.891-40.172l-20.781-20.781-3.234-3.25-20.797-20.797c-12.531 7.516-40.188 19.891-40.188 19.891a19.889 19.889 0 00-11.969 14.844l-14.016 65.266-8.016 8.031c-2.906-.016-5.813 1.047-8.016 3.25-4.344 4.359-4.344 11.391 0 15.734s11.392 4.342 15.736-.001zm47.593-80.5c4.734-4.75 12.422-4.75 17.188 0 4.734 4.734 4.734 12.438 0 17.172-4.766 4.734-12.453 4.734-17.188 0-4.75-4.735-4.75-12.438 0-17.172zM362.346 318.766l-44.313-44.328-20.531 20.531c-5.016 5-11.531 3.5-11.531 3.5l-6 6.031 21.031 21.031 5.016 5 26.297 26.297 6-6.016s-1.5-6.5 3.5-11.516l20.531-20.53zM497.83 138.969c-20.5-20.484-42.844-18.625-66.141 4.656-23.266 23.281-90.219 106.625-106 122.406l45.078 45.063c15.766-15.766 99.109-82.719 122.391-106s25.156-45.641 4.672-66.125zm-20.344 36.172l-70.156 70.141a4.349 4.349 0 01-6.203 0l-9.625-9.625c-1.703-1.688-1.703-4.469 0-6.188l70.141-70.156a4.413 4.413 0 016.234 0l9.609 9.625c1.719 1.718 1.719 4.5 0 6.203z"
                          className="st0"
                        ></path>
                        <path
                          d="M88.408 201.844H282.908V223.953H88.408z"
                          className="st0"
                        ></path>
                        <path
                          d="M88.408 279.219H234.28300000000002V301.313H88.408z"
                          className="st0"
                        ></path>
                        <path
                          d="M88.408 356.563H192.299V378.67199999999997H88.408z"
                          className="st0"
                        ></path>
                        <path
                          d="M358.58 356.969l-8.063 6.656-7.938 7.938v102.156H26.518V38.281h213.281v83.484a21.485 21.485 0 006.313 15.203 21.52 21.52 0 0015.219 6.313h81.25v76.5c4.297-5.125 8.813-10.469 13.391-15.922 4.313-5.141 8.719-10.391 13.125-15.625v-72.578L265.221 11.766H11.049C4.94 11.766.002 16.719.002 22.813v466.375c0 6.094 4.938 11.047 11.047 11.047h347c6.109 0 11.047-4.953 11.047-11.047V337.031l-12.672 12.672 2.156 7.266zm-96.656-311l75.188 75.203h-75.188V45.969z"
                          className="st0"
                        ></path>
                      </g>
                    </svg>

                    <input
                      className="input_field-login"
                      placeholder="Contrato de Alianza"
                      name="contrato"
                      type="text"
                      value={user.contrato.toUpperCase()}
                      onChange={handleChange}
                      required
                    />
                  </div>

                  <div className="input_container-login">
                    <label className="input_label-login">Contraseña</label>
                    <svg
                      fill="none"
                      viewBox="0 0 24 24"
                      height="24"
                      width="24"
                      xmlns="http://www.w3.org/2000/svg"
                      className="icon"
                    >
                      <path
                        strokeLinecap="round"
                        strokeWidth="1.5"
                        stroke="#141B34"
                        d="M18 11.0041C17.4166 9.91704 16.273 9.15775 14.9519 9.0993C13.477 9.03404 11.9788 9 10.329 9C8.67911 9 7.18091 9.03404 5.70604 9.0993C3.95328 9.17685 2.51295 10.4881 2.27882 12.1618C2.12602 13.2541 2 14.3734 2 15.5134C2 16.6534 2.12602 17.7727 2.27882 18.865C2.51295 20.5387 3.95328 21.8499 5.70604 21.9275C6.42013 21.9591 7.26041 21.9834 8 22"
                      ></path>
                      <path
                        strokeLinejoin="round"
                        strokeLinecap="round"
                        strokeWidth="1.5"
                        stroke="#141B34"
                        d="M6 9V6.5C6 4.01472 8.01472 2 10.5 2C12.9853 2 15 4.01472 15 6.5V9"
                      ></path>
                      <path
                        fill="#141B34"
                        d="M21.2046 15.1045L20.6242 15.6956V15.6956L21.2046 15.1045ZM21.4196 16.4767C21.7461 16.7972 22.2706 16.7924 22.5911 16.466C22.9116 16.1395 22.9068 15.615 22.5804 15.2945L21.4196 16.4767ZM18.0228 15.1045L17.4424 14.5134V14.5134L18.0228 15.1045ZM18.2379 18.0387C18.5643 18.3593 19.0888 18.3545 19.4094 18.028C19.7299 17.7016 19.7251 17.1771 19.3987 16.8565L18.2379 18.0387ZM14.2603 20.7619C13.7039 21.3082 12.7957 21.3082 12.2394 20.7619L11.0786 21.9441C12.2794 23.1232 14.2202 23.1232 15.4211 21.9441L14.2603 20.7619ZM12.2394 20.7619C11.6914 20.2239 11.6914 19.358 12.2394 18.82L11.0786 17.6378C9.86927 18.8252 9.86927 20.7567 11.0786 21.9441L12.2394 20.7619ZM12.2394 18.82C12.7957 18.2737 13.7039 18.2737 14.2603 18.82L15.4211 17.6378C14.2202 16.4587 12.2794 16.4587 11.0786 17.6378L12.2394 18.82ZM14.2603 18.82C14.8082 19.358 14.8082 20.2239 14.2603 20.7619L15.4211 21.9441C16.6304 20.7567 16.6304 18.8252 15.4211 17.6378L14.2603 18.82ZM20.6242 15.6956L21.4196 16.4767L22.5804 15.2945L21.785 14.5134L20.6242 15.6956ZM15.4211 18.82L17.8078 16.4767L16.647 15.2944L14.2603 17.6377L15.4211 18.82ZM17.8078 16.4767L18.6032 15.6956L17.4424 14.5134L16.647 15.2945L17.8078 16.4767ZM16.647 16.4767L18.2379 18.0387L19.3987 16.8565L17.8078 15.2945L16.647 16.4767ZM21.785 14.5134C21.4266 14.1616 21.0998 13.8383 20.7993 13.6131C20.4791 13.3732 20.096 13.1716 19.6137 13.1716V14.8284C19.6145 14.8284 19.619 14.8273 19.6395 14.8357C19.6663 14.8466 19.7183 14.8735 19.806 14.9391C19.9969 15.0822 20.2326 15.3112 20.6242 15.6956L21.785 14.5134ZM18.6032 15.6956C18.9948 15.3112 19.2305 15.0822 19.4215 14.9391C19.5091 14.8735 19.5611 14.8466 19.5879 14.8357C19.6084 14.8273 19.6129 14.8284 19.6137 14.8284V13.1716C19.1314 13.1716 18.7483 13.3732 18.4281 13.6131C18.1276 13.8383 17.8008 14.1616 17.4424 14.5134L18.6032 15.6956Z"
                      ></path>
                    </svg>
                    <input
                      className="input_field-login"
                      placeholder="Contraseña de Alianza"
                      name="password"
                      type={ showPassword ? "text" : "password"}
                      value={user.password}
                      onChange={handleChange}
                      required
                    />
                    {
                      showPassword ? 
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 28 28" className="icon_password" onClick={ () => setShowPassword(!showPassword)}>
                        <g fill="#003893" fillRule="evenodd" clipRule="evenodd">
                          <path d="M17.747 15.415c.238-.54.372-1.143.372-1.413 0-2.348-1.824-4.25-4.073-4.25-2.25 0-4.073 1.902-4.073 4.25 0 2.347 2.037 3.887 4.073 3.887 1.303 0 2.462-.637 3.206-1.63a4.29 4.29 0 00.495-.844zm-3.701.349c.51 0 .975-.196 1.332-.518.432-.39.622-.965.622-1.244 0-1.174-.828-2.126-1.954-2.126-1.125 0-2.046.95-2.046 2.124 0 .81.92 1.764 2.046 1.764z"></path>
                          <path d="M1.092 14.272a.448.448 0 010-.545c.01-.012.019-.025.028-.039C4.78 8.343 9.32 5.5 14.014 5.5c4.689 0 9.224 2.836 12.882 8.17.069.101.104.205.104.33 0 .13-.04.24-.114.346C23.23 19.67 18.698 22.5 14.014 22.5c-4.705 0-9.257-2.857-12.922-8.228zm2.847-.92a1.04 1.04 0 000 1.294c3.135 3.898 6.665 5.729 10.075 5.729 3.41 0 6.94-1.83 10.075-5.729a1.04 1.04 0 000-1.294c-3.135-3.897-6.665-5.727-10.075-5.727-3.41 0-6.94 1.83-10.075 5.727z"></path>
                        </g>
                      </svg>
                      :
                      <svg 
                        className="icon_password"
                        xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 28 28"
                        onClick={ () => setShowPassword(!showPassword)}
                      >
                        <g fill="#000">
                          <path
                            fillRule="evenodd"
                            d="M22.693 1.55a.794.794 0 00-1.093.297l-2.447 4.297c-1.667-.78-3.392-1.18-5.139-1.18-4.693 0-9.233 2.882-12.894 8.3l-.015.021-.012.019a.46.46 0 000 .552c2.7 4.013 5.884 6.641 9.255 7.746L8.4 25.022a.817.817 0 00.293 1.108l.347.203a.794.794 0 001.092-.297L23.332 2.86a.817.817 0 00-.293-1.108l-.346-.203zm-4.601 6.457c-1.357-.597-2.727-.888-4.078-.888-3.41 0-6.94 1.854-10.075 5.805-.3.38-.3.932 0 1.311 2.35 2.962 4.922 4.746 7.499 5.454l1.348-2.366c-1.54-.49-2.813-1.86-2.813-3.741 0-2.38 1.824-4.308 4.073-4.308 1.038 0 1.986.41 2.705 1.087l1.341-2.354zm-2.453 4.307c-.346-.537-.916-.886-1.593-.886-1.125 0-2.046.963-2.046 2.152 0 .786.843 1.705 1.902 1.782l1.737-3.048z"
                            clipRule="evenodd"
                          ></path>
                          <path d="M14.687 22.176c4.444-.261 8.719-3.107 12.2-8.245A.605.605 0 0027 13.58a.571.571 0 00-.104-.335c-1.338-1.977-2.794-3.616-4.33-4.9l-1.06 1.86c.883.76 1.747 1.665 2.583 2.719.301.38.301.932 0 1.311-2.521 3.178-5.299 5-8.064 5.592l-1.338 2.35z"></path>
                        </g>
                      </svg>
                    }
                  </div>
                  
                  <div className='centrar'>
                    <button className='mt-3 btn-login' type='submit' disabled={disabled}>
                      {loading ? (
                        <>
                          <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                          {' '}Cargando...
                        </>
                      ) : (
                      'Iniciar Sesión '
                      )}
                    </button>
                  </div>
                  <div className='mt-4 centrar'>
                    <a className='text-title' href='https://api.whatsapp.com/send?phone=573137597403&text=Buen%20d%C3%ADa%20%20%0ARequiero%20ayuda%20para%20ingresar%20al%20portal%20de%20clientes%20afiliados' target="_blank"  rel="noreferrer noopener">
                      ¿Necesitas ayuda?
                    </a>
                  </div>  
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default Signin