import React from 'react';
import { createBrowserRouter, RouterProvider} from 'react-router-dom'
import Signin from '../components/auth/signin';
import PageNotFound from '../components/err/404';
import Dashboard from '../layout/dashboard';
import Booking from '../pages/booking';
import Alliance from '../pages/alliance';
import Support from '../pages/support';
import Coupons from '../pages/coupons';
import Coupon from '../pages/coupon';
import Bidding from '../pages/bidding';
import Bid from '../pages/bid';

const RouterApp = () => {

  const router = createBrowserRouter([
    {
      path: "/",
      element: <Signin />,
      errorElement: <h1>Error en ruta inicial</h1> 
    },
    {
      path: "/dashboard",
      element: <Dashboard />,
      errorElement: <h1>Error en Dashboard</h1> 
    },
    {
      path: "/reserva",
      element: <Booking />,
      errorElement: <h1>Error en reserva</h1> 
    },
    {
      path: "/alianza-plus",
      element: <Alliance />,
      errorElement: <h1>Error en Alianza plus</h1> 
    },
    {
      path: "/soporte",
      element: <Support />,
      errorElement: <h1>Error en Soporte</h1> 
    },
    {
      path: "/cupones",
      element: <Coupons />,
      errorElement: <h1>Error en Cupones</h1> 
    },
    {
      path: "/cupon/:id",
      element: <Coupon />,
      errorElement: <h1>Error en Cupon id</h1> 
    },
    {
      path: "/ofertas",
      element: <Bidding />,
      errorElement: <h1>Error en Ofertas</h1> 
    },
    {
      path: "/oferta/:id",
      element: <Bid />,
      errorElement: <h1>Error en Oferta: id</h1> 
    },
    {
      path: "*",
      element: <PageNotFound />
    },
  ]);
  return (
    <RouterProvider router={router} />
  )
}

export default RouterApp