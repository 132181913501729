import axios from 'axios';
import React, { useEffect, useState } from 'react'
import baseUrl from '../utils/baseUrl';
import FeaturedCoupons from '../components/cupons/FeaturedCoupons';
import HighlightedOffers from '../components/offers/HighlightedOffers';
import Banners from '../components/Banners';
import Index from '../layout/Index';
import Load from '../common/Load';

const Alliance = () => {

  const [cuponesDestacados, setCuponesDestacados] = useState(null)
  const [ofertasDestacadas, setOfertasDestacadas] = useState(null)

  const GetCupones = async() =>{
    const url = `${baseUrl}/api/cupones/destacados`;
    const response = await axios.get(url,{
      header : 'Content-Type Aplication/json',
    });
    setCuponesDestacados(response.data)
  }

  const GetOfertasDestacadas = async () => {
    const url = `${baseUrl}/api/ofertas/destacados`;
    const response = await axios.get(url,{
      header : 'Content-Type Aplication/json',
    });
    setOfertasDestacadas(response.data)
  }
  useEffect(() => {

    const user = localStorage.getItem("userInfo")
    if(!user){
      setTimeout(() => {
        window.location.href = "/";
      }, "0")
    }
    GetCupones()
    GetOfertasDestacadas()
  }, [])

  return (
    <Index>
      <div className='container'>
        <Banners />
        <h1 className='text-center title-alianza-plus m-4'>Cupones destacados</h1>
          { 
            cuponesDestacados ?
              <FeaturedCoupons cupon={cuponesDestacados} />
            :
            <div className='centrar'>
              <span className="loader mt-5"></span>
            </div>
          }
        <h1 className='text-center title-alianza-plus m-4'>Ofertas Destacadas</h1>
          {
            ofertasDestacadas ?
              <HighlightedOffers ofertasDestacadas={ofertasDestacadas} /> 
            :
            <Load />
          }
      </div>
    </Index>
  )
}

export default Alliance