import axios from 'axios';
import confetti from 'canvas-confetti';
import React, { useContext, useEffect, useState } from 'react'
import { NavLink, useParams } from 'react-router-dom';
import { DataContext } from '../context/DataContext';
import { ValidateCoupon } from '../service/validateCoupon';
import baseUrl from '../utils/baseUrl';
import Load from '../common/Load';
import Index from '../layout/Index';

const Coupon = () => {
  const {id} = useParams();
  const {data} = useContext(DataContext)
  const [cupon, setCupon] = useState(null)
  const [redimir, setRedimir] = useState(false)
  const [couponRedeem, setCouponRedeem] = useState(false)

  const GetCuponByID = async() =>{
    const url = `${baseUrl}/api/cupones/cuponById/${id}`;
    const response = await axios.post(url,{
      header : 'Content-Type Aplication/json',
    });
    setCupon(response.data[0])
  }

  const validate = async() =>{
    try {
      const response = await ValidateCoupon(id, data.contracto.contrato)
      if(response.data === 1) setRedimir(true)
    } catch (error) {
      console.log(error);
    }
  }

  const handleSubmit = async(e) => {
    e.preventDefault();
    setRedimir(true)  
    setCouponRedeem(true)
    let end = Date.now() + (3 * 1000);
    let colors = ['#003893', '#ffcf04'];
    
    (function frame() {
      confetti({
        particleCount: 2,
        angle: 60,
        spread: 55,
        origin: { x: 0 },
        colors: colors
      });
      confetti({
        particleCount: 2,
        angle: 120,
        spread: 55,
        origin: { x: 1 },
        colors: colors
      });

      if (Date.now() < end) {
        requestAnimationFrame(frame);
      }
    }());
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });  
  }

  useEffect(() => {
    const user = localStorage.getItem("userInfo")
    if(!user){
      setTimeout(() => {
        window.location.href = "/";
      }, "0")
    }
    validate()
    GetCuponByID()
  }, [])

  return (
    <Index>
      {
        cupon != null ?
          <div className='container'>
            <h2 className="text-center title-alianza-plus">{cupon?.nombre_convenio}</h2>
              <div className='row'>
                <div className='col-lg-12 col-md-12 col-sm-12'>
                  <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item">
                        <NavLink className="text-dark" to={`/alianza-plus`}>Alianza plus</NavLink>
                      </li>
                      <li className="breadcrumb-item">
                        <NavLink className="text-dark" to={`/cupones`}>Cupones</NavLink>
                      </li>
                      <li className="breadcrumb-item active" aria-current="page">Redimir</li>
                    </ol>
                  </nav>
                  <div className='row'>
                    {
                      cupon ?
                        <>
                          <div className='col-sm-12 col-md-5 col-lg-5 col-xl-5'>
                            <img src={cupon?.img} className="card-img-top w-100" alt="..." />
                          </div>
                          <div className='col-sm-12 col-md-7 col-lg-7 col-xl-7'>
                            {
                              couponRedeem ?
                              <div className="m-1 text-center alert alert-success alert-dismissible fade show" role="alert">
                                <strong>¡Felicidades!</strong> este cupón se ha redimido correctamente.
                              </div> : ''
                            }
                              <h2 className="title-sections">{cupon?.titulo}</h2>
                              <p className="">Unidades disponibles {cupon?.redimir}</p>
                              <p dangerouslySetInnerHTML={{ __html: cupon?.descuento}}></p>
                                <button className='mt-4 btn-oferta'disabled={redimir}  data-bs-toggle="modal" data-bs-target="#exampleModal">
                                  { redimir ? 'Este cupón ya fue redimido' : 'Redimir'}
                                </button>
                          </div>

                          <div className="modal fade" id="exampleModal" aria-labelledby="exampleModalLabel" aria-hidden="true">
                              <div className="modal-dialog">
                                <div className="modal-content">
                                  <div className="modal-body text-center">
                                    ¿Desea redimir esta cupón?
                                  </div>
                                  <div className="modal-footer row">
                                    <button type="button" className="col-5 btn btn-outline-danger" data-bs-dismiss="modal">No, cancelar</button>
                                      <button 
                                        type="button" 
                                        className="btn-oferta col-6" 
                                        data-bs-dismiss="modal" 
                                        onClick={ (e) => handleSubmit(e) }
                                      >
                                        Si, redimir</button>
                                  </div>
                                </div>
                              </div>
                            </div>
                        </>
                      : 
                      <div className='centrar'>
                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                      </div>
                    }
                  </div>
                  <p className='mt-5 text-center'><strong>Terminos y Condiciones</strong></p>
                  <p>{cupon?.tyc}</p>
                </div>
              </div>
          </div>
        : 
          <Load />
      }
    </Index>
  )
}

export default Coupon