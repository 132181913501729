import React, { useEffect, useState } from 'react'
import Banner from '../components/banner'
import confetti from 'canvas-confetti'
import { getPrice } from '../service/getPrice'
import Promotions from '../components/promotions';
import Index from '../layout/Index';
import Load from '../common/Load';


function capitalizar(str) {
  return str.replace(/\w\S*/g, function(txt){
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
}

const Booking = () => {

  const [urlPrice, setUrlPrice] = useState(null)
  const [allData, setAllData] = useState(false)
  const data = JSON.parse(localStorage.getItem('userInfo'));

  if(data.afiliado1.email.length < 0 && data.afiliado1.titular < 0  && data.contracto.ciudad < 0 && data.contracto.contrato < 0){
    setAllData(true)
  }
  const crossLogin = async () =>{
    try {
      const response = await getPrice();
      setUrlPrice(response.data)
      console.log(response.data)
    } catch (error) {
      console.log("Error token price");
    }
  }

  useEffect(() => {
    const user = localStorage.getItem("userInfo")
    if(!user){
      setTimeout(() => {
        window.location.href = "/";
      }, "0")
    }
    crossLogin()
    confetti({
      spread: 660,
      ticks: 50,
      gravity: 0,
      decay: 0.94,
      startVelocity: 30,
      shapes: ['star'],
      colors: ['FFE400', 'FFBD00', 'E89400', 'FFCA6C', 'FDFFB8'],
      particleCount: 50,
      scalar: 1.2,
    })
  }, [])
  
  return (
    <Index>
      <Banner text={capitalizar(data?.afiliado1?.titular) || ''} />
        {
          urlPrice != null
          ?
            <> 
              { !allData
                ?
                  <div className='row centrar'>
                    <div className="col-sm-12 col-md-12 col-lg-7 col-xl-7">
                      <Promotions urlPrice={urlPrice}  />
                    </div>
                    <div className='col-sm-12 col-md-12 col-lg-4 col-xl-4'>
                      <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                          {
                            urlPrice ? 
                            <a 
                              href={urlPrice } 
                              target="_blank" 
                              rel="noopener noreferrer" 
                            >
                              <div className='cardHotel'>
                                <div className="centrar m-1">
                                  <img src='/images/AlianzaPriority2.webp' alt='Hoteles con tarifas negociadas y reducidas  - N&R'/>
                                </div>  
                                <h6 className='text-center text-italic'>Hoteles con tarifas negociadas y reducidas  - N&R  </h6>
                                <button className='btn-reservar mt-2'>Reservar</button>
                              </div>
                            </a>
                            : 
                            ''
                          }
                      </div>
                      <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                        <a 
                          href="https://wa.link/ort3zr"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <div className='cardHotel'>   
                            <div className="centrar m-1">
                              <img src='/images/icono-de-whatsapp.png' alt='Whatsapp' className='' style={{ height: '70px'}}/>
                            </div>  
                            <h6 className='text-center text-italic'>Contactar a un experto para reservar tus noches</h6>
                            <button className='btn-reservar '>Contactar</button>
                          </div>
                        </a>
                      </div>
                    </div>
                  </div>
                : 
                <div className="toast fade show" role="alert" >
                  <div className="toast-header">
                    <svg className="bd-placeholder-img rounded me-2" width="400" height="20" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" preserveAspectRatio="xMidYMid slice" focusable="false"><rect width="100%" height="100%" fill="#007aff"></rect></svg>
                
                    <strong className="me-auto">Información importante</strong>
                    <small>11 mins ago</small>
                    {/* <button type="button" className="btn-close" data-bs-dismiss="toast" aria-label="Close"></button> */}
                  </div>
                  <div className="toast-body">
                    Para realizar una reserva a través de nuestro portal debes tener actualizados tus datos, si deseas realizar actualización de tus datos comunicate a la línea <a className='text-primary' href="tel:+576015520100"> (601)5520100</a>
                  </div>
                </div>
              }
            </>
          : 
            <Load />
        }
    </Index>
  )
}

export default Booking