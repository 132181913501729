import React, { useContext } from 'react'

const Promotions = ( {urlPrice}) => {

  return (
    <div id="carouselExampleCaptions" className="carousel slide carousel-fade" data-bs-ride="carousel">
      <div className="carousel-indicators">
        <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
        <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="1" aria-label="Slide 2"></button>
        <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="2" aria-label="Slide 3"></button>
        <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="3" aria-label="Slide 4"></button>
        <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="4" aria-label="Slide 5"></button>
      </div>
      <div className="carousel-inner">
        <div className="carousel-item active" data-bs-interval="1000">
          <a href={`${urlPrice}`} target='_blank' rel='noreferrer'>
            <img src="/promos/oferta2.png" className="card-carousel" alt="..." rel="noreferrer"/>
          </a>
        </div>
        <div className="carousel-item" data-bs-interval="1000">
          <a href={`${urlPrice}`} target='_blank' rel='noreferrer'>
            <img src="/promos/oferta3.png" className="card-carousel" alt="..." rel="noreferrer"/>
          </a>
        </div>
        <div className="carousel-item" data-bs-interval="1000">
          <a href={`${urlPrice}`} target='_blank' rel='noreferrer'>
            <img src="/promos/oferta4.png" className="card-carousel" alt="..." rel="noreferrer"/>
          </a>
        </div>
        <div className="carousel-item " data-bs-interval="1000">
          <a href={`${urlPrice}`} target='_blank' rel='noreferrer'>
            <img src="/promos/img_maya.png" className="card-carousel" alt="..." rel="noreferrer"/>
          </a>
        </div>
        <div className="carousel-item" data-bs-interval="1000">
          <a href={`${urlPrice}`} target='_blank' rel='noreferrer'>
            <img src="/promos/oferta1.png" className="card-carousel" alt="..." rel="noreferrer"/>
          </a>
        </div>
      </div>
      <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="prev">
        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
      </button>
      <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="next">
        <span className="carousel-control-next-icon" aria-hidden="true"></span>
      </button>
    </div>
  )
}

export default Promotions