import React from 'react'
import { NavLink } from 'react-router-dom';
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';

const responsive = {
  0: { items: 1 },
  568: { items: 2 },
};

const HighlightedOffers = ( {ofertasDestacadas}) => {

  const dolar = (price) =>{
    const price1 = price.split(" ")
    return price1[0];
  }

  const items = [
    ofertasDestacadas?.map( (oferta, index) =>(
      <div className='' data-value={index+1} key={index}>
        <NavLink to={`/oferta/${oferta.id}`}>
          <div className="cardOferta cardOfertas card" >
            <div className='row'>
              <div className='col-lg-6 col-md-6 col-sm-6'>
                <div className="example__ribbon example__ribbon--tr">
                  <span 
                    className="example__title" 
                  >
                    Ver oferta
                  </span>
                </div>
                <img src={oferta.portada} className="card-img-top" alt="..." />
              </div>
              <div className='col-lg-6 col-md-6 col-sm-6'>
                <div className='traslucido'>
                  <div className="card-body mt-5">
                    <h6 className="">{oferta.titulo}</h6>
                    <p className="text-dark">Expira {oferta.expira}</p>
                    
                    <div className="row">
                      <div className="col-sm">
                        <h6>Precio Regular:</h6>
                        <del className='price-tag2'>
                            {oferta.precio_regular.includes('Dolares') ? dolar(oferta.precio_regular) : oferta.precio_regular }</del>
                      </div>
                      <div className="col-sm">
                        <h6>Precio Afiliado:</h6>
                        <p className='price-tag'>{oferta.precio_descuento.includes('Dolares') ? dolar(oferta.precio_descuento) : oferta.precio_descuento}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </NavLink>
      </div>
    ))
  ];

  return (
    <AliceCarousel
      mouseTracking
      controlsStrategy="alternate"
      autoPlay={true}
      autoPlayInterval={2000}
      animationType="fadeout" 
      animationDuration={800}
      disableButtonsControls
      items={ items[0] }
      infinite
      responsive={responsive}
    />
  )
}

export default HighlightedOffers