import React from 'react'
import { NavLink, useLocation } from 'react-router-dom'

const Menu = () => {
  const location = useLocation();

  const logOut = () =>{
    localStorage.clear();
  }
  return (
    <div className='menu-lateral'>
      <NavLink to="/dashboard">
        <button className={`${location.pathname === '/dashboard' ? 'btn-menu-active' : 'btn-menu'}`}>
          <img src='/icons/Membresia.svg' alt='icon_membresia' height={30}/>
          Membresía
        </button>
      </NavLink>
      <NavLink to="/reserva">
        <button className={`${location.pathname === '/reserva' ? 'btn-menu-active' : 'btn-menu'}`}>
          <img src='/icons/Reserva.svg' alt='icon_reservar' height={30}/>
          Reservar
        </button>
      </NavLink>
      <NavLink to="/alianza-plus">
        <button className={`${location.pathname === '/alianza-plus' || location.pathname === '/cupones' ||  location.pathname === '/ofertas' || location.pathname.includes('/oferta/') || location.pathname.includes('/cupon/')
                ? 'btn-menu-active' : 'btn-menu'}`
            }>
          <img src='/images/Logo-Alianza-Plus.png' alt='icons_plus' height={30}/> {' '}
          Alianza Plus
        </button>
      </NavLink>
      {
        location.pathname === '/alianza-plus' || location.pathname === '/cupones' || location.pathname === '/ofertas' || location.pathname === '/redenciones' ? 
          <a href='https://www.assistcard.com/co/b2c/grupo-alianza-colombia' target="_blank" rel="noreferrer">
            <button
            className={`${location.pathname === '/redenciones' ? 'submenu' : 'btn-menu submenu'}`}
            >
              <img src='/icons/right.svg' alt='icon_redentions' height={30}/>
              Assist Card
            </button>
          </a>
        : ''  
      }
      <NavLink to="/soporte">
        <button className={`${location.pathname === '/soporte' ? 'btn-menu-active' : 'btn-menu'}`}>
          <img src='/icons/Soporte.svg' alt='icon_soporte' height={30}/>
          Soporte
        </button>
      </NavLink>
      <NavLink to="/">
        <button className='btn-menu' onClick={ () => logOut()}>
          <img src='/icons/logout.svg' alt='icon_out' height={30}/>
          Cerrar Sesión
        </button>
      </NavLink>
    </div>
  )
}

export default Menu