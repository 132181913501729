import React from 'react'
import { NavLink } from 'react-router-dom'

const Banners = () => {
  return (
    <>
      <div id="carouselExampleIndicators" className="carousel slide carousel-fade" data-bs-ride="true" >
        <div className="carousel-indicators">
          <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
          <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"></button>
          <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2" aria-label="Slide 3"></button>
        </div>
        <div className="carousel-inner">
          
          <div className="carousel-item active ">
            <NavLink to="/cupon/36">
              <img src="/alianza/banner_katlon.jpg" className="br-20 center-img" alt="..."  />
            </NavLink>  
          </div>
          <div className="carousel-item ">
            <NavLink to="/cupon/34">
              <img src="/alianza/banner-body-tech.jpg" alt="..." className='br-20 center-img' />
            </NavLink>  
          </div>
          <div className="carousel-item ">
            <NavLink to="/oferta/78">
              <img src="/alianza/banner1.jpg" alt="..." className='br-20 center-img'  />
            </NavLink>  
          </div>
          
        </div>
        <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
          <span className="carousel-control-prev-icon" aria-hidden="true"></span>
          <span className="visually-hidden">Previous</span>
        </button>
        <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
          <span className="carousel-control-next-icon" aria-hidden="true"></span>
          <span className="visually-hidden">Next</span>
        </button>
      </div>

      <div className='centrar mt-3'>
        <div className='row'>
          <div className='col-sm-12 col-md-6 col-lg-6 col-xl-6 ' >
            <NavLink to="/cupones">
              <img src="/alianza/cupones.png" className="br-20 m-1" alt="..." />
            </NavLink>
          </div>
          <div className='col-sm-12 col-md-6 col-lg-6 col-xl-6 '>
            <NavLink to="/ofertas">
              <img src="/alianza/ofertas.jpg" className="br-20 m-1" alt="..." />
            </NavLink>
          </div>
        </div>
      </div>
    </>
  )
}

export default Banners