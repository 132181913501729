import React, { useContext, useEffect, useState } from 'react';
import { NavLink, useParams } from 'react-router-dom';
import axios from 'axios';
import baseUrl from '../utils/baseUrl';
import { ValidateBid } from '../service/validateCoupon';
import { DataContext } from '../context/DataContext';
import confetti from 'canvas-confetti';
import Load from '../common/Load';
import Starts from '../common/Starts';
import Index from '../layout/Index';

const Bid = () => {
  
  const {id} = useParams();
  const {data} = useContext(DataContext)
  const [oferta, setOferta] = useState(null)
  const [redimir, setRedimir] = useState(false)
  const [couponRedeem, setCouponRedeem] = useState(false)

  const getBidById = async () => {
    const url = `${baseUrl}/api/ofertas/ofertaById/${id}`;
    const response = await axios.post(url,{
      header : 'Content-Type Aplication/json',
    });
    setOferta(response.data[0])
  }
  
  const validate = async () => {
    try {
      const response = await ValidateBid(id, data.contracto.contrato)
      if(response.data === 1) setRedimir(true)
    } catch (error) {
      console.log("error");
    }
  }

  const handleSubmit = async(e) => {
    e.preventDefault();
    setRedimir(true)
    setCouponRedeem(true);

    let end = Date.now() + (3 * 1000);
    let colors = ['#003893', '#ffcf04'];
    
    (function frame() {
      confetti({
        particleCount: 2,
        angle: 60,
        spread: 55,
        origin: { x: 0 },
        colors: colors
      });
      confetti({
        particleCount: 2,
        angle: 120,
        spread: 55,
        origin: { x: 1 },
        colors: colors
      });

      if (Date.now() < end) {
        requestAnimationFrame(frame);
      }
    }());
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }

  useEffect(() => {
  
    const user = localStorage.getItem("userInfo")
    if(!user){
      setTimeout(() => {
        window.location.href = "/";
      }, "0")
    }
    validate()
    getBidById()
  }, [])
  
  return (
    <Index>
      {
        oferta != null ?
          <div className='container'>
            <h3 className='text-center title-alianza-plus'>{oferta.hotel}</h3>
              <div className='row'>
                <div className='col-lg-12 col-md-12 col-sm-12'>
                  <nav aria-label="breadcrumb centrar">
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item">
                        <NavLink className="text-dark" to={`/alianza-plus`}>Alianza plus</NavLink>
                      </li>
                      <li className="breadcrumb-item">
                        <NavLink className="text-dark" to={`/ofertas`}>Ofertas</NavLink>
                      </li>
                      <li className="breadcrumb-item active" aria-current="page">Redimir</li>
                    </ol>
                  </nav>
                  {
                    oferta ?
                    <>
                      <div className='row'>
                        <div className='col-lg-6 col-md-6 col-sm-6'>
                          <div id="carouselExampleIndicators" className="carousel slide carousel-fade" data-bs-ride="true">
                            <div className="carousel-indicators">
                              <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                              <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"></button>
                              <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2" aria-label="Slide 3"></button>
                              <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="3" aria-label="Slide 4"></button>
                              <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="4" aria-label="Slide 5"></button>
                            </div>
                            <div className="carousel-inner">
                              <div className="carousel-item active">
                                <img src={oferta?.img1}className="d-block w-100" alt="..." />
                              </div>
                              <div className="carousel-item" data-bs-interval="2000">
                                <img src={oferta?.img2} className="d-block w-100" alt="..." />
                              </div>
                              <div className="carousel-item" data-bs-interval="2000">
                                <img src={oferta?.img3} className="d-block w-100" alt="..." />
                              </div>
                              <div className="carousel-item" data-bs-interval="2000">
                                <img src={oferta?.img4} className="d-block w-100" alt="..." />
                              </div>
                              <div className="carousel-item" data-bs-interval="2000">
                                <img src={oferta?.img5} className="d-block w-100" alt="..." />
                              </div>
                            </div>
                            <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
                              <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                              <span className="visually-hidden">Previous</span>
                            </button>
                            <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
                              <span className="carousel-control-next-icon" aria-hidden="true"></span>
                              <span className="visually-hidden">Next</span>
                            </button>
                          </div>
                          
                        </div>
                        <div className='col-lg-6 col-md-6 col-sm-6'>
                          {
                            couponRedeem ?
                            <div className="m-2 text-center alert alert-success alert-dismissible fade show p-3" role="alert">
                              <strong>¡Felicidades!</strong> esta oferta se ha redimido correctamente.
                            </div> : ''
                          }
                            <h1 className="title-sections text-center">{oferta?.titulo}</h1>
                            <Starts score={5} />

                              <p className='text-danger centrar'>({Math.floor(Math.random() * (37 - 19 + 1)) + 19} reviews)</p>
                            <div className="row centrar">
                              <div className="col-5 text-danger">
                                <h6>Precio Regular:</h6><del className='price-tag2'>{oferta.precio_regular}</del>
                              </div>
                              <div className="col-5">
                                <h6>Precio Afiliado:</h6>
                                <p className='price-tag'>{oferta.precio_descuento}</p>
                              </div>
                            </div>
                            <div className="row centrar">
                              <div className="col-6">
                                <p className="">Unidades disponibles: <strong>{oferta?.cupo}</strong></p>
                              </div>
                              <div className="col-6">
                                <p className="">Oferta valida hasta: <strong>{oferta?.expira}</strong></p>
                              </div>
                            </div>

                            <p className='centrar'><strong>Descripción</strong></p>
                            <p dangerouslySetInnerHTML={{ __html: oferta?.descripcion}}></p>

                              <button className='mt-4 btn-oferta'disabled={redimir} data-bs-toggle="modal" data-bs-target="#exampleModal">
                                { redimir ? 'Este oferta ya fue redimida' : 'Redimir'}
                              </button>

                                <div className="modal fade" id="exampleModal" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                  <div className="modal-dialog">
                                    <div className="modal-content">
                                      <div className="modal-body text-center">
                                        ¿Desea redimir esta oferta?
                                      </div>
                                      <div className="modal-footer">
                                        <button type="button" className="btn btn-outline-danger" data-bs-dismiss="modal">No, cancelar</button>
                                          <button 
                                            type="button" 
                                            className="btn-oferta" 
                                            data-bs-dismiss="modal" 
                                            onClick={ (e) => handleSubmit(e) }
                                          >
                                            Si, redimir</button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                        </div>
                      </div>
                      <p className='mt-5 text-center'><strong>Terminos y Condiciones</strong></p>
                      <p>{oferta?.tyc}</p>
                    </>
                    :
                    <div className='centrar mt-5'>
                      <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    </div>
                  }
                </div>
              </div>
          </div>
        :  <Load />
      }
    </Index>
  )
}

export default Bid