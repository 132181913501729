import axios from "axios";
import baseUrl from "../utils/baseUrl";

const getPrice = async() =>{
  const data = JSON.parse(localStorage.getItem('userInfo'));
  let emailUser = '';

  if(data.afiliado1.email.includes(';')){
    const email = data.afiliado1.email.split(';')
    emailUser = email[0];
  }else{
    emailUser = data.afiliado1.email;
  }
  const payload = {
    email: emailUser,
    nombre: data.afiliado1.titular,
    ciudad: data.contracto.ciudad,
    contrato: data.contracto.contrato,
  } 

  const url = `${baseUrl}/api/users/getprice`;
  try {
    const response = await axios.post(url, payload, {
      header : 'Content-Type Aplication/json',
    });
    return response;
  } catch (error) {
    console.log({error});
  }
}

export {
  getPrice
}