
import { useEffect } from 'react';

const ChatWood = () => {
  useEffect(() => {
    // Add Chatwoot Settings
    window.chatwootSettings = {
      hideMessageBubble: false,
      position: 'right', // This can be left or right
      locale: 'es', // Language to be set
      type: 'standard', // [standard, expanded_bubble]
      // darkMode: 'auto',
      // launcherTitle: 'custom msj'
    };

    // Paste the script from inbox settings except the <script> tag
    (function (d, t) {
      const BASE_URL = "https://chatwoot-production-bb9c.up.railway.app";
      const websiteToken = 'hRTbcuc7B2NCtsKh81rp8cqi';
      var g = d.createElement(t), s = d.getElementsByTagName(t)[0];
      g.src = BASE_URL + "/packs/js/sdk.js";
      s.parentNode.insertBefore(g, s);
      g.async = true;
      g.onload = function () {
        window.chatwootSDK.run({
          websiteToken: websiteToken,
          baseUrl: BASE_URL,
        });
      };
    })(document, "script");
  }, []);

  return null;
};

export default ChatWood;