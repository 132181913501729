import React from 'react'

const NoData = () => {
  return (
    <div className="alert alert-warning text-justify" role="alert">
        Disculpa las molestias, nuestro sitio está actualmente en mantenimiento para ofrecerte más. Puedes contactarnos a través de 
        <a  className='' href= "mailto:sac@grupoalianzacolombia.com"> sac@grupoalianzacolombia.com </a>
        <a  className='' href= "mailto:reservas@grupoalianzacolombia.com"> reservas@grupoalianzacolombia.com</a>
                    
    </div>
  )
}

export default NoData