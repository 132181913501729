import React, { useEffect } from 'react'
import Navbar from '../components/Navbar'
import Menu from '../layout/menu'

const Support = () => {
  useEffect(() => {
    const user = localStorage.getItem("userInfo")
    if(!user){
      setTimeout(() => {
        window.location.href = "/";
      }, "0")
    }
  }, [])
  
  return (
    <div className='row' style={{ margin: 0}}>
        <Navbar />
        <Menu />
          <div className='col-sm-12 col-md-10 col-lg-10 col-xl-10'>
            <div className='container'>
              <div className="card-afiliado">
                <div className="img-avatar"></div>
                <div className="card-afiliado-text">
                  <div className="portada"></div>
                  <div className="title-total">   
                    <div className="text-center"><h4> Lineas de contacto en Bogotá Colombia </h4></div>
                    <div className="desc">
                      <p> Apreciado cliente, si presenta alguna duda o inquietud, comuníquese a nuestras líneas 
                      de atención de lunes a viernes de 8:00 am a 6:00 pm y sábados de 9:00 am a 3:00 pm.</p>
                      <p>lineas:  
                        <a className='text-dark' href="tel:+576015520100"> (601)5520100</a> -
                        <a className='text-dark' href="tel:+576012205214"> (601)2205214</a>
                      </p>

                      <p>
                        <a  className='text-dark' href= "mailto:sac@grupoalianzacolombia.com"> sac@grupoalianzacolombia.com </a>
                        <a  className='text-dark' href= "mailto:reservas@grupoalianzacolombia.com"> reservas@grupoalianzacolombia.com</a>
                      </p>
                    </div>
                    <div className="actions">
                    </div>
                  </div>
                </div>
              </div>
            </div>
        </div>
    </div>
  )
}

export default Support