import React, { useContext, useEffect, useState } from 'react'
import { DataContext } from '../context/DataContext'
import Banner from '../components/banner';
import Menu from './menu';
import ChangePassword from '../components/changePassword';
import Navbar from '../components/Navbar';
import Promotions from '../components/promotions';
import UserInfo from '../components/userInfo';
import { getPrice } from '../service/getPrice';
import Load from '../common/Load';

const Dashboard = () => {
  
  const {data} = useContext(DataContext)
  const [urlPrice, setUrlPrice] = useState('')

  function capitalizar(str) {
    return str.replace(/\w\S*/g, function(txt){
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  }

  const crossLogin = async () =>{
    try {
      const response = await getPrice();
      setUrlPrice(response.data)
    } catch (error) {
      console.log("Error token price");
    }
  }

  useEffect(() => {
    const user = localStorage.getItem("userInfo")
    if(!user){
      setTimeout(() => {
        window.location.href = "/";
      }, "0")
    }
    crossLogin()
  }, [])
  
  return (
    <div>
        {
          data?.validaciones?.chpwd === '1' ?  <ChangePassword /> 
          :
          <div>
            {
              data != null ?
                <div className='row' style={{ margin: 0}}>
                  <Navbar />
                  <Menu />
                  <div className='col-sm-12 col-md-10 col-lg-10 col-xl-10'>
                    <div className='container'>
                      <div  id="carouselExampleSlidesOnly" className="carousel slide" data-bs-ride="carousel">
                        <div className="carousel-inner">
                          <div className="carousel-item active" data-bs-interval="5000">
                            <Banner text={capitalizar(data?.afiliado1?.titular) || ''} />
                          </div>
                          {
                            data?.afiliado2?.titular ?
                              <div className="carousel-item" data-bs-interval="5000">
                                <Banner text={capitalizar(data?.afiliado2?.titular) || ''} />
                              </div>
                            : ''  
                          }
                        </div>
                      </div>
                      <div className='row'>
                        <div className='col-sm-12 col-md-12 col-lg-7 col-xl-7'>
                          <Promotions urlPrice={urlPrice}/>
                        </div>
                        <div className='col-sm-12 col-md-12 col-lg-5 col-xl-5'>
                          <UserInfo data={data}/>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              : <Load />
            }
          </div>
        }
    </div>
  )
}

export default Dashboard